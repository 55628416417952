.input {
    width: 100%;
    border : 1px solid #ffffff;
    border-Radius: 7px;
    text-decoration: none;
    font-size: 1em;
    color: #ffffff !important;
    padding: 5px 0;
  }

  .MuiInputBase-input {
    height: 100%;
  }
  .manage_background{
    background-color:#1f1f1f;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blur_effect {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-Index: 0;
    background-image: url('../assets/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(20px);
  }

  .customSelectWrapper {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: #000 !important;
    border-color: #ffffff !important;
    border: 1px solid #ffffff;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .menuItemStyle {
    text-align: right;
    color: #ffffff !important;
    background-color: #000;
  }
  .paper {
    background-color: #000;
    height: 110px;
    border: 1px solid #ffffff;
    overflow-y: auto;
  }
  .customArrow {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background-color: #000;
    height: 100%;
    width: 3rem;
    pointer-events: none;
  }
  select {
    font-size: 14px;
    background-color: #000;
  }

  .MuiSelect-icon {
    z-index:  1;
    right: .7rem;
    color: #8991a4;
  }
  .MuiSelect-select {
    color: #fff !important;
    background-color: #000 !important;
    padding-left: 10px;
  }

    
  .main_wrapper{
      margin: 5%;
    }

  @media screen and (max-width: 900px) {
     .main_wrapper{
      width: 100%
     }
  }

  @media screen and (min-width: 900px) {
    .main_wrapper{
     width: 60%
    }
 }

 