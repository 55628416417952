.tabsBox {
    width: 100%;
    border: 1px solid rgb(72 72 72);
    border-radius: 10px 10px 0 0;
}

.tabsBox .tabs_main .MuiTabs-indicator {
    background-color: #171617;
    border-radius: 1.5px;
    height: 4px;
}

.tabsBox .tabs_main .Mui-selected {
    color: #0CC8A8;
    background-color: #1c1b1b;
    border-radius: 10px 10px 0 0;
}

.tabsBox .headingTabs {
    color: #ffffff;
    font-size: 18px;
    min-width: 50%;
    /* font-family: "SFPro-Regular"; */
    text-transform: inherit;
    background-color: rgb(42, 42, 42);
}

.tableStyle {
    /* border-top-width: 1px; */
    min-width: 550px;
    border: 1px solid rgb(72 72 72);
    /* border-top-color: rgb(72, 72, 72);
    border-top-style: solid; */
    max-height: calc(100vh - 200px);
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 0 0 10px 10px;
    background-color: black;
}

.tableCellWidth {
    min-width: 100px;
}

.minwidth160 {
    min-width: 160px;
}
.textlastChild{
    width:380px;
}

@media screen and (max-width: 768px){
    .setMarginLeft{
        margin-left:0 !important;
    }
    .textlastChild{
        width:auto;
    }
}
@media screen and (max-width: 600px) {
    .gridBoxjoinMetting {
        top: 8% !important;
        left: 7% !important;
        height: 100% !important;
        transform: translate(-4%, -9%) !important;
    }

    .dNonegrid {
        display: none;
    }

    .tableStyle table th,
    .tableStyle table td {
        padding: 10px;
        white-space: nowrap;
    }

    .boxGrid8pad {
        padding: 14px !important;
        margin-top: 20px !important;
    }

    .minwidth160 {
        min-width: 70px;
    }

    .tableCellWidth {
        min-width: 50px;
    }

    .tableStyle {
        min-width: auto;
        width: 100%;
    }
}